import React, { ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';
import BaseComponent from '../../../../base/base-component';
import { IMapChartTooltipProperties } from './map-chart-tooltip-interface';
import './map-chart-tooltip.scss';

export default class MapChartTooltip extends BaseComponent<IMapChartTooltipProperties> {
  private static readonly TOOLTIP_WIDTH: number = 190;
  private static readonly HEADER_HEIGHT: number = 112;

  public render(): ReactNode {
    const { children } = this.props;

    return (
      <ReactTooltip
        className="map-tooltip"
        backgroundColor="#fff"
        textColor="#000"
        type="dark"
        effect="float"
        clickable={true}
        eventOff=""
        overridePosition={({ left, top }) => {
          if (this.isMobileDevice()) {
            return {
              left: screen.width / 2 - MapChartTooltip.TOOLTIP_WIDTH / 2,
              top: MapChartTooltip.HEADER_HEIGHT,
            };
          }
          return {
            left,
            top,
          };
        }}
      >
        {children}
      </ReactTooltip>
    );
  }
}
