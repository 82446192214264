export enum SODGradeColor {
  'A' = '#48A078',
  'A+' = '#248F76',
  'A-' = '#68B179',
  'B' = '#ACD07D',
  'B+' = '#89C07B',
  'B-' = '#D0DE81',
  'C' = '#F1CD6B',
  'C+' = '#F6EB88',
  'C-' = '#EBAF52',
  'D' = '#DA7135',
  'D+' = '#E49040',
  'D-' = '#CF4F30',
  'F' = '#C02530',
}

export enum SORGradeColor {
  LOW = '#ADEBD4',
  MEDIUM = '#2EB88A',
  HIGH = '#248F76',
}
